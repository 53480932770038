.grid {
	display: grid;
	--columns: 1;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	gap: var(--gutter);
}

.grid--center {
	align-items: center;
}

.grid__item {
	grid-column: span var(--columns);
}

@media (min-width: 576px) {
	.grid {
		--columns: 12;
	}
	.grid__item--sm-1 {
		grid-column: span 1;
	}
	.grid__item--sm-2 {
		grid-column: span 2;
	}
	.grid__item--sm-3 {
		grid-column: span 3;
	}
	.grid__item--sm-4 {
		grid-column: span 4;
	}
	.grid__item--sm-5 {
		grid-column: span 5;
	}
	.grid__item--sm-6 {
		grid-column: span 6;
	}
	.grid__item--sm-7 {
		grid-column: span 7;
	}
	.grid__item--sm-8 {
		grid-column: span 8;
	}
	.grid__item--sm-9 {
		grid-column: span 9;
	}
	.grid__item--sm-10 {
		grid-column: span 10;
	}
	.grid__item--sm-11 {
		grid-column: span 11;
	}
	.grid__item--sm-12 {
		grid-column: span 12;
	}
}

@media (min-width: 768px) {
	.grid__item--md-1 {
		grid-column: span 1;
	}
	.grid__item--md-2 {
		grid-column: span 2;
	}
	.grid__item--md-3 {
		grid-column: span 3;
	}
	.grid__item--md-4 {
		grid-column: span 4;
	}
	.grid__item--md-5 {
		grid-column: span 5;
	}
	.grid__item--md-6 {
		grid-column: span 6;
	}
	.grid__item--md-7 {
		grid-column: span 7;
	}
	.grid__item--md-8 {
		grid-column: span 8;
	}
	.grid__item--md-9 {
		grid-column: span 9;
	}
	.grid__item--md-10 {
		grid-column: span 10;
	}
	.grid__item--md-11 {
		grid-column: span 11;
	}
	.grid__item--md-12 {
		grid-column: span 12;
	}
}

ul.grid {
	padding-left: 0;
}

ul.grid li {
	margin-left: 1.5rem;
}
