.announce-stripe {
	color: #fff;
	background: linear-gradient(to right, #be3380 0%, #d1385c 150%);
	background: linear-gradient(
		to right,
		color(display-p3 0.745 0.2 0.5019) 0%,
		color(display-p3 0.8196 0.2196 0.3608) 150%
	);
	font-weight: 600;
	text-align: left;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: var(--page-margin);
	padding-right: var(--page-margin);
	display: flex;
	flex-direction: row;
}

.announce-stripe svg {
	vertical-align: -0.25em;
	margin-right: 0.70rem;
}
