.page-wrap {
	box-sizing: content-box;
	padding-left: var(--page-margin);
	padding-right: var(--page-margin);
	margin-left: auto;
	margin-right: auto;
	max-width: var(--page-width);
}

.page-wrap + .page-wrap:not(.page-section) {
	margin-top: var(--page-section-narrow);
}
