.donate-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: var(--gutter);
}

@media (max-width: 575px) {
	.donate-bar {
		flex-direction: column;
	}
}

.donate-bar__link {
	font-weight: 700;
	color: var(--color-blue);
	text-decoration: none;
}
