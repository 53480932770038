.clippings {
	padding-left: 0;
}

.clippings li {
	display: block;
	margin-bottom: 1.5rem;
}

.clippings a {
	display: inline-block;
}

.clippings__logo {
	display: block;
	width: 100%;
	max-width: 50%;
	height: auto;

	margin-bottom: 0.75rem;
}

@supports (object-fit: contain) {
	.clippings__logo {
		width: 3rem;
		height: 2.5rem;
		object-fit: contain;
		object-position: left bottom;
	}

	.up-size {
		width: 10rem;
	}
}
