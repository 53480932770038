.button {
	--color: var(--color-white);
	--border: var(--color-blue);
	--background: var(--color-blue);
	--outline: transparent;
	--shadow: transparent;

	color: var(--color);
	background-color: var(--background);
	border: 1px solid var(--border);
	box-shadow: 0 0 0 0.15rem var(--outline), 0 0 0.5rem var(--shadow);
	border-radius: 0.4rem;
	padding: 0.7rem 1.4rem 0.8rem;
	font-size: 0.8rem;
	font-weight: 700;

	transition-property: color, background-color, border-color, box-shadow;
	transition-duration: 100ms;
	transition-timing-function: ease-out;

	/* reset user-agent styles */
	display: inline-block;
	appearance: none;
	line-height: 1.3;
	text-decoration: none;
	cursor: pointer;
}

.button:hover {
	--background: var(--color-blue-600);
}

/**
 * Copy button specifics
 */

.button--copy {
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
	max-width: 100%;
}

.button__divider {
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	vertical-align: middle;
}

.button__abbrev {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
	margin-right: 0.9rem;
}

.button__action::after {
	content: '';
	display: inline-block;
	height: 1.2rem;
	width: 1.2rem;
	background-size: 1.2rem 1.2rem;
	background-repeat: no-repeat;
	margin-left: 0.4rem;
	margin-top: -0.4rem;
	margin-bottom: -0.3rem;
	vertical-align: middle;
}

.button--copy .button__action::after {
	background-image: url('../../assets/link.svg');
}

.button--copied .button__action::after {
	background-image: url('../../assets/checkmark.svg');
}
