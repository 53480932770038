.payment-info {
}

.payment-info__heading {
	color: #005bbb;
	font-weight: 700;
}

.payment-info__qr {
	display: block;
	max-width: 100%;
	height: auto;
	border-radius: 18.5%;
}

@media (max-width: 767px) {
	.payment-info__qr {
		width: 260px;
		margin-left: auto;
		margin-right: auto;
	}
}
