:root {
	--font-family: 'Open Sans', sans-serif;

	/* Base colors */
	--color-white: #fff;
	--color-black: #000;
	--color-blue: #005bbb;
	--color-blue-100: #ebf2fa;
	--color-blue-600: #004996;

	/* Color assignments */
	--color-text: #252525;
	--color-background: var(--color-white);
	--color-section-highlight: var(--color-blue-100);

	--page-width: 60rem;
	--page-margin: 8vw;
	--page-section: 6rem;
	--page-section-narrow: 2.25rem;
	--paragraph-margin: 1rem;
	--gutter: 1.9rem;

	--est-header-height: 4.9rem;
}

@media (max-width: 767px) {
	:root {
		--page-section: 3rem;
		--page-section-narrow: 1.75rem;
		--gutter: 0.75rem;
	}
}

@media (max-width: 1024px) {
	:root {
		--gutter: 1.25rem;
	}
}
