.progress-bar {
    background-color: rgb(211, 209, 209);
    border-radius: 10px;
    -moz-box-shadow:    inset 0 0 10px  rgb(160, 160, 160);
	-webkit-box-shadow: inset 0 0 10px rgb(160, 160, 160);
	box-shadow:         inset 0 0 10px  rgb(160, 160, 160);    
}

.progress-bar-raised {
    background-color: var(--color-blue);
    border-radius: 10px;
    padding: 0.5em;
    text-align: center;
}

.progress-bar-raised p {
    color: var(--color-white);
    font-size: 0.8em;
    font-weight: 600;
}
