html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --font-family: "Open Sans", sans-serif;
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #005bbb;
  --color-blue-100: #ebf2fa;
  --color-blue-600: #004996;
  --color-text: #252525;
  --color-background: var(--color-white);
  --color-section-highlight: var(--color-blue-100);
  --page-width: 60rem;
  --page-margin: 8vw;
  --page-section: 6rem;
  --page-section-narrow: 2.25rem;
  --paragraph-margin: 1rem;
  --gutter: 1.9rem;
  --est-header-height: 4.9rem;
}

@media (max-width: 767px) {
  :root {
    --page-section: 3rem;
    --page-section-narrow: 1.75rem;
    --gutter: .75rem;
  }
}

@media (max-width: 1024px) {
  :root {
    --gutter: 1.25rem;
  }
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-cyrillic-ext-400-normal.b67780e2.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-cyrillic-400-normal.218769bf.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-greek-ext-400-normal.758f4407.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-greek-400-normal.9ed965c0.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-hebrew-400-normal.2985c97b.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-vietnamese-400-normal.04a6c4e3.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-latin-ext-400-normal.b68bdac8.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-latin-400-normal.b5b03397.woff2") format("woff2"), url("open-sans-all-400-normal.7c9c26a4.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-cyrillic-ext-400-italic.6be30661.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-cyrillic-400-italic.923bd970.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-greek-ext-400-italic.be147a37.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-greek-400-italic.b20f8285.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-hebrew-400-italic.e8ba36ca.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-vietnamese-400-italic.442e9099.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-latin-ext-400-italic.94574ed5.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-latin-400-italic.112c3b3e.woff2") format("woff2"), url("open-sans-all-400-italic.190b8734.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-cyrillic-ext-600-normal.781f4c33.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-cyrillic-600-normal.7009e994.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-greek-ext-600-normal.18527bfb.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-greek-600-normal.00eac0ea.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-hebrew-600-normal.56bff6b8.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-vietnamese-600-normal.ba9a0ed6.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-latin-ext-600-normal.a7ddd478.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-latin-600-normal.12dc94da.woff2") format("woff2"), url("open-sans-all-600-normal.f8224c47.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-cyrillic-ext-600-italic.e7602d37.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-cyrillic-600-italic.d8c38947.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-greek-ext-600-italic.18b7a634.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-greek-600-italic.7103bda3.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-hebrew-600-italic.a0b9ca89.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-vietnamese-600-italic.1be7f990.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-latin-ext-600-italic.586c030b.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("open-sans-latin-600-italic.69da6b68.woff2") format("woff2"), url("open-sans-all-600-italic.8c2b3921.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-cyrillic-ext-700-normal.cd1c9a0a.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-cyrillic-700-normal.4c88d110.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-greek-ext-700-normal.f024ff20.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-greek-700-normal.c0f9aec6.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-hebrew-700-normal.04b4f310.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-vietnamese-700-normal.bd303ac4.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-latin-ext-700-normal.02a4828d.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-latin-700-normal.c5b2a342.woff2") format("woff2"), url("open-sans-all-700-normal.6b4c12ab.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-cyrillic-ext-700-italic.78455011.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-cyrillic-700-italic.ebde4136.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-greek-ext-700-italic.f1cdd944.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-greek-700-italic.bfdf5a46.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-hebrew-700-italic.75bf58da.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-vietnamese-700-italic.442f6530.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-latin-ext-700-italic.a2efbfb8.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-latin-700-italic.0c61e89b.woff2") format("woff2"), url("open-sans-all-700-italic.da13ee1b.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



* {
  box-sizing: border-box;
}

:before, :after {
  box-sizing: inherit;
}

html {
  background-color: var(--color-background);
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  font-size: 20px;
}

html.s-scrolled-down {
  background-color: var(--color-blue-100);
}

body {
  max-width: 100vw;
  background-color: var(--color-background);
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

button {
  display: inline-block;
}

a {
  color: var(--color-blue-600);
}

html {
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 1.5;
}

body {
  font-size: 1.2rem;
}

@media (max-width: 575px) {
  body {
    font-size: .9rem;
  }

  .t-small {
    font-size: .7rem;
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-weight: 700;
  line-height: 1.25;
}

h1, .h1 {
  font-size: 2.4rem;
}

h2, .h2 {
  font-size: 1.8rem;
}

h3, .h3, h4, .h4 {
  font-size: 1.25rem;
}

p, ul, ol, table, figure, blockquote, dl, h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin-top: 0;
  margin-bottom: 0;
}

p:not(:last-child), ul:not(:last-child), ol:not(:last-child), table:not(:last-child), figure:not(:last-child), blockquote:not(:last-child), dl:not(:last-child), h1:not(:last-child), .h1:not(:last-child) {
  margin-bottom: var(--paragraph-margin);
}

h2:not(:first-child), .h2:not(:first-child) {
  margin-top: calc(var(--paragraph-margin)  * 2);
}

h2:not(:last-child), .h2:not(:last-child) {
  margin-bottom: calc(var(--paragraph-margin)  * 1);
}

h3:not(:first-child), .h3:not(:first-child) {
  margin-top: calc(var(--paragraph-margin)  * 2);
}

h3:not(:last-child), .h3:not(:last-child) {
  margin-bottom: var(--paragraph-margin);
}

h4:not(:first-child), .h4:not(:first-child) {
  margin-top: calc(var(--paragraph-margin)  * 1.5);
}

h4:not(:last-child), .h4:not(:last-child) {
  margin-bottom: calc(var(--paragraph-margin)  * .5);
}

.t-small {
  font-size: 1rem;
}

.t-x-small {
  font-size: .9rem;
}

.t-d-top {
  margin-top: calc(var(--paragraph-margin)  * 1.75);
}

.logo {
  --size: 1.8rem;
  color: var(--color-blue-600);
  text-transform: uppercase;
  font-weight: 700;
  font-size: calc(var(--size)  * .6667);
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
}

.logo:before {
  content: "";
  width: calc(var(--size)  * 1.49);
  height: var(--size);
  background: url("ukraine.91320893.png") center no-repeat;
  background-size: calc(var(--size)  * 1.49) var(--size);
  vertical-align: middle;
  margin-right: .48rem;
  display: inline-block;
}

.page-header {
  --padding: 1.5rem;
  padding-top: var(--padding);
  padding-bottom: var(--padding);
  background-color: var(--color-background);
  --shadow: 0;
  box-shadow: 0 -1rem 1rem 1rem rgba(0, 0, 0, var(--shadow) ), 0 0 0 .05rem rgba(0, 0, 0, calc(var(--shadow)  * .25) );
  transition: padding .2s ease-in-out, box-shadow .2s linear;
}

.page-header__wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 767px) {
  .page-header__wrap {
    flex-direction: column;
    gap: 1rem;
  }
}

.page-header__nav {
  gap: 1.8rem;
  font-size: .9rem;
  display: flex;
}

.page-header__nav a {
  text-decoration: none;
}

.page-header__flag {
  font-size: 1.4rem;
  line-height: 1;
}

@media (min-width: 768px) {
  .page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .s-scrolled-down .page-header {
    --shadow: .07;
    --padding: .5rem;
  }

  body {
    padding-top: var(--est-header-height);
  }

  body:before {
    content: "";
    height: var(--est-header-height);
    background-color: var(--color-blue-100);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.page-footer__links {
  justify-content: flex-end;
  display: flex;
}

.page-footer__links a {
  text-decoration: none;
}

.page-footer__links a:first-child {
  margin-right: 1.8rem;
}

@media (max-width: 767px) {
  .page-footer__description {
    text-align: center;
  }

  .page-footer__links {
    justify-content: center;
  }
}

.page-section {
  margin-top: var(--page-section);
  margin-bottom: var(--page-section);
}

.page-section--highlight {
  background-color: var(--color-section-highlight);
  padding-top: var(--page-section);
  padding-bottom: var(--page-section);
  margin-top: 0;
  margin-bottom: 0;
}

.page-section--narrow {
  --page-section: var(--page-section-narrow);
}

.page-section--divider {
  padding-top: var(--page-section);
  border-top: 1px solid #0000001a;
}

.page-wrap {
  box-sizing: content-box;
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  max-width: var(--page-width);
  margin-left: auto;
  margin-right: auto;
}

.page-wrap + .page-wrap:not(.page-section) {
  margin-top: var(--page-section-narrow);
}

.grid {
  --columns: 1;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr) );
  gap: var(--gutter);
  display: grid;
}

.grid--center {
  align-items: center;
}

.grid__item {
  grid-column: span var(--columns);
}

@media (min-width: 576px) {
  .grid {
    --columns: 12;
  }

  .grid__item--sm-1 {
    grid-column: span 1;
  }

  .grid__item--sm-2 {
    grid-column: span 2;
  }

  .grid__item--sm-3 {
    grid-column: span 3;
  }

  .grid__item--sm-4 {
    grid-column: span 4;
  }

  .grid__item--sm-5 {
    grid-column: span 5;
  }

  .grid__item--sm-6 {
    grid-column: span 6;
  }

  .grid__item--sm-7 {
    grid-column: span 7;
  }

  .grid__item--sm-8 {
    grid-column: span 8;
  }

  .grid__item--sm-9 {
    grid-column: span 9;
  }

  .grid__item--sm-10 {
    grid-column: span 10;
  }

  .grid__item--sm-11 {
    grid-column: span 11;
  }

  .grid__item--sm-12 {
    grid-column: span 12;
  }
}

@media (min-width: 768px) {
  .grid__item--md-1 {
    grid-column: span 1;
  }

  .grid__item--md-2 {
    grid-column: span 2;
  }

  .grid__item--md-3 {
    grid-column: span 3;
  }

  .grid__item--md-4 {
    grid-column: span 4;
  }

  .grid__item--md-5 {
    grid-column: span 5;
  }

  .grid__item--md-6 {
    grid-column: span 6;
  }

  .grid__item--md-7 {
    grid-column: span 7;
  }

  .grid__item--md-8 {
    grid-column: span 8;
  }

  .grid__item--md-9 {
    grid-column: span 9;
  }

  .grid__item--md-10 {
    grid-column: span 10;
  }

  .grid__item--md-11 {
    grid-column: span 11;
  }

  .grid__item--md-12 {
    grid-column: span 12;
  }
}

ul.grid {
  padding-left: 0;
}

ul.grid li {
  margin-left: 1.5rem;
}

.button {
  --color: var(--color-white);
  --border: var(--color-blue);
  --background: var(--color-blue);
  --outline: transparent;
  --shadow: transparent;
  color: var(--color);
  background-color: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0 0 0 .15rem var(--outline), 0 0 .5rem var(--shadow);
  appearance: none;
  cursor: pointer;
  border-radius: .4rem;
  padding: .7rem 1.4rem .8rem;
  font-size: .8rem;
  font-weight: 700;
  line-height: 1.3;
  text-decoration: none;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  display: inline-block;
}

.button:hover {
  --background: var(--color-blue-600);
}

.button--copy {
  white-space: nowrap;
  max-width: 100%;
  align-items: center;
  display: inline-flex;
}

.button__divider {
  vertical-align: middle;
  margin-left: .75rem;
  margin-right: .75rem;
}

.button__abbrev {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  margin-right: .9rem;
  overflow: hidden;
}

.button__action:after {
  content: "";
  height: 1.2rem;
  width: 1.2rem;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 1.2rem 1.2rem;
  margin-top: -.4rem;
  margin-bottom: -.3rem;
  margin-left: .4rem;
  display: inline-block;
}

.button--copy .button__action:after {
  background-image: url("link.7e83aa08.svg");
}

.button--copied .button__action:after {
  background-image: url("checkmark.3ac93b03.svg");
}

img.image, .image > img {
  max-width: 100%;
  height: auto;
  display: block;
}

img.image--small, .image--small > img {
  border-radius: .4rem;
}

img.image--large, .image--large > img {
  margin-top: 2.4rem;
}

.image__caption {
  margin-top: 1rem;
  font-weight: 700;
}

figure.image {
  margin-left: 0;
  margin-right: 0;
}

.donate-bar {
  justify-content: space-between;
  align-items: center;
  gap: var(--gutter);
  display: flex;
}

@media (max-width: 575px) {
  .donate-bar {
    flex-direction: column;
  }
}

.donate-bar__link {
  color: var(--color-blue);
  font-weight: 700;
  text-decoration: none;
}

.logos {
  --columns: 4;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr) );
  align-items: center;
  gap: var(--gutter);
  display: grid;
}

@media (max-width: 575px) {
  .logos {
    --columns: 2;
  }
}

.logos img {
  width: 100%;
  max-width: 50%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@supports (object-fit: contain) {
  .logos img {
    max-width: 60%;
    height: 4.5rem;
    object-fit: contain;
    object-position: center center;
  }
}

.clippings {
  padding-left: 0;
}

.clippings li {
  margin-bottom: 1.5rem;
  display: block;
}

.clippings a {
  display: inline-block;
}

.clippings__logo {
  width: 100%;
  max-width: 50%;
  height: auto;
  margin-bottom: .75rem;
  display: block;
}

@supports (object-fit: contain) {
  .clippings__logo {
    width: 3rem;
    height: 2.5rem;
    object-fit: contain;
    object-position: left bottom;
  }

  .up-size {
    width: 10rem;
  }
}

.payment-info__heading {
  color: #005bbb;
  font-weight: 700;
}

.payment-info__qr {
  max-width: 100%;
  height: auto;
  border-radius: 18.5%;
  display: block;
}

@media (max-width: 767px) {
  .payment-info__qr {
    width: 260px;
    margin-left: auto;
    margin-right: auto;
  }
}

.progress-bar {
  background-color: #d3d1d1;
  border-radius: 10px;
  box-shadow: inset 0 0 10px #a0a0a0;
}

.progress-bar-raised {
  background-color: var(--color-blue);
  text-align: center;
  border-radius: 10px;
  padding: .5em;
}

.progress-bar-raised p {
  color: var(--color-white);
  font-size: .8em;
  font-weight: 600;
}

.announce-stripe {
  color: #fff;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  background: linear-gradient(to right, #cf1e82 0%, #e4205a 150%);
  background: linear-gradient(to right, color(display-p3 .745 .2 .5019) 0%, color(display-p3 .8196 .2196 .3608) 150%);
  flex-direction: row;
  font-weight: 600;
  display: flex;
}

.announce-stripe svg {
  vertical-align: -.25em;
  margin-right: .7rem;
}

.campaign_season__heading {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.campaign_season__heading_margin {
  margin-top: 2.4rem;
}



/*# sourceMappingURL=index.b2658fe6.css.map */
