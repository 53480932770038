img.image,
.image > img {
	max-width: 100%;
	height: auto;
	display: block;
}

img.image--small,
.image--small > img {
	border-radius: 0.4rem;
}

img.image--large,
.image--large > img {
	margin-top: 2.4rem;
}

.image__caption {
	margin-top: 1rem;
	font-weight: 700;
}

figure.image {
	margin-left: 0;
	margin-right: 0;
}
