.logo {
	--size: 1.8rem;
	color: var(--color-blue-600);
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 700;
	font-size: calc(var(--size) * 0.6667);
	user-select: none;
}

.logo::before {
	content: '';
	display: inline-block;
	width: calc(var(--size) * 1.49);
	height: var(--size);
	background: url(../../assets/ukraine.png) center no-repeat;
	background-size: calc(var(--size) * 1.49) var(--size);
	vertical-align: middle;
	margin-right: 0.48rem;
}
