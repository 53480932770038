* {
	box-sizing: border-box;
}

*::before,
*::after {
	box-sizing: inherit;
}

html {
	font-size: 20px; /* <- 1rem */
	background-color: var(--color-background);
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
}

html.s-scrolled-down {
	background-color: var(--color-blue-100);
}

body {
	margin: 0;
	max-width: 100vw;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: var(--color-background);
}

button {
	display: inline-block;
}

a {
	color: var(--color-blue-600);
}
