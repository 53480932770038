.page-section {
	margin-top: var(--page-section);
	margin-bottom: var(--page-section);
}

.page-section--highlight {
	background-color: var(--color-section-highlight);
	padding-top: var(--page-section);
	padding-bottom: var(--page-section);
	margin-top: 0;
	margin-bottom: 0;
}

.page-section--narrow {
	--page-section: var(--page-section-narrow);
}

.page-section--divider {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: var(--page-section);
}
