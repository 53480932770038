.logos {
	display: grid;
	--columns: 4;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	align-items: center;
	gap: var(--gutter);
}

@media (max-width: 575px) {
	.logos {
		--columns: 2;
	}
}

.logos img {
	display: block;
	width: 100%;
	max-width: 50%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}

@supports (object-fit: contain) {
	.logos img {
		max-width: 60%;
		height: 4.5rem;
		object-fit: contain;
		object-position: center center;
	}
}
