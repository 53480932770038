.page-header {
	--padding: 1.5rem;
	padding-top: var(--padding);
	padding-bottom: var(--padding);
	background-color: var(--color-background);

	--shadow: 0;
	box-shadow: 0 -1rem 1rem 1rem rgba(0, 0, 0, var(--shadow)),
		0 0 0 0.05rem rgba(0, 0, 0, calc(var(--shadow) * 0.25));

	transition: padding 200ms ease-in-out, box-shadow 200ms linear;
}

.page-header__wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (max-width: 767px) {
	.page-header__wrap {
		gap: 1rem;
		flex-direction: column;
	}
}

.page-header__nav {
	font-size: 0.9rem;
	display: flex;
	gap: 1.8rem;
}

.page-header__nav a {
	text-decoration: none;
}

.page-header__flag {
	font-size: 1.4rem;
	line-height: 1;
}

@media (min-width: 768px) {
	.page-header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}

	.s-scrolled-down .page-header {
		--shadow: 0.07;
		--padding: 0.5rem;
	}

	body {
		padding-top: var(--est-header-height);
	}

	body::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: var(--est-header-height);
		background-color: var(--color-blue-100);
	}
}
