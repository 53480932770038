.page-footer__links {
	display: flex;
	justify-content: flex-end;
}

.page-footer__links a {
	text-decoration: none;
}

.page-footer__links a:first-child {
	margin-right: 1.8rem;
}

@media (max-width: 767px) {
	.page-footer__description {
		text-align: center;
	}

	.page-footer__links {
		justify-content: center;
	}
}
